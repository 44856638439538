<template>
  <div class="full-area vertically-centered bg-image">
    <QuestionHeader
      :data="data"
      :no-question-title="true"
      :no-title="true"
    ></QuestionHeader>

    <content class="content-wrapper delay-entry short-delay">
      <vue-markdown class="html-content">{{data.fields.content}}</vue-markdown>

      <div class="grid-wrapper">
        <div class="field-wrapper" v-for="item in data.fields.fields">
          <el-input placeholder="Email" v-model="form.data.email" v-if="item === 'email'" :key="item" :required="data.fields.mandatory" type="email"></el-input>
          <el-input placeholder="Apellido" v-model="form.data.lastname" v-if="item === 'lastName'" :key="item" :required="data.fields.mandatory"></el-input>
          <el-input placeholder="Nombre" v-model="form.data.name" v-if="item === 'firstName'" :key="item" :required="data.fields.mandatory"></el-input>
          <el-input placeholder="Teléfono o CC" v-model="form.data.phoneId" v-if="item === 'phone/id'" :key="item" :required="data.fields.mandatory"></el-input>
          <el-input placeholder="CC o identificación personal" v-model="form.data.id" v-if="item === 'id'" :key="item" :required="data.fields.mandatory"></el-input>
          <el-input placeholder="Teléfono" v-model="form.data.phone" v-if="item === 'phone'" :key="item" :required="data.fields.mandatory"></el-input>
          <el-input placeholder="Ciudad" v-model="form.data.city" v-if="item === 'city'" :key="item" :required="data.fields.mandatory"></el-input>

          <div v-if="item === 'date'">
            <el-date-picker
              v-model="form.data.date"
              type="date"
              placeholder="Fecha"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>

          <el-radio v-model="form.data.sex" label="male" v-if="item === 'sex'" :key="item + '_male'" :required="data.fields.mandatory">Masculino</el-radio>
          <el-radio v-model="form.data.sex" label="female" v-if="item === 'sex'" :key="item + '_female'" :required="data.fields.mandatory">Femenino</el-radio>

          <el-select v-model="form.data.dropdown" placeholder="--" v-if="item === 'dropdown'" :key="item">
            <el-option
              v-for="option in data.fields.options"
              :key="option"
              :label="option"
              :value="option">
            </el-option>
          </el-select>

          <el-select v-model="form.data.ethnicity" placeholder="Etnia" v-if="item === 'Etnia'" :key="item">
            <el-option
              v-for="option in ethnicGroups"
              :key="option.value"
              :label="option.label"
              :value="option.value">
            </el-option>
          </el-select>
          <el-select v-model="form.data.age" placeholder="Edad" v-if="item === 'Edad'" :key="item">
            <el-option
              v-for="option in years"
              :key="option"
              :label="option"
              :value="option">
            </el-option>
          </el-select>
        </div>
        <div class="field-wrapper" v-if="data.fields.terms">
          <el-checkbox v-model="form.data.terms" v-if="data.fields.terms" :key="'terms'" :required="true">Acepto los términos y condiciones</el-checkbox>
          <a @click="showTerms = true" class="terms">(ver)</a>
          <el-drawer
            :visible.sync="showTerms"
            size="90%"
          >
            <vue-markdown class="html-content">{{data.fields.terms.fields.text}}</vue-markdown>          
          </el-drawer>
        </div>
      </div>

      <footer>
        <div class="button-wrapper">
          <el-button
            :disabled="disableSubmit"
            rounded
            type="danger"
            @click="next()"
          >Enviar</el-button>
        </div>
      </footer>
    </content>

  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'Demographic',

  props: [ 'data' ],

  components: {
    VueMarkdown,
    QuestionHeader: () => import('Components/base/QuestionHeader')
  },

  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },      
      valid: false,
      showTerms: false,
      form: {
        question: this.data.fields.title,
        id: this.data.sys.id,
        data: {
          name: null,
          lastname: null,
          email: null,
          age: null,
          sex: null,
          city: null,
          phoneId: null,
          phone: null,
          id: null,
          age: {},
          ethnicity: null,
          dropdown: null,
          terms: false
        },
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      },
      ethnicGroups: [
        { label: 'Ninguna', value: 'ninguna' },
        { label: 'Afrodescendiente', value: 'afrodescendiente' },
        { label: 'Indígena', value: 'indígena' }
      ]
    }
  },

  computed: {
    disableSubmit () {
      if (!this.form.data.terms && this.data.fields.terms) { return true }
      if (this.data.fields.fields.includes('phone/id') && !(this.form.data.phoneId && this.form.data.phoneId.length > 6 )) { return true }
      if (!this.form.data.name && this.data.fields.fields.includes('firstName')) { return true }
      if (!this.form.data.lastname && this.data.fields.fields.includes('lastName')) { return true }
      if (!this.form.data.email && this.data.fields.fields.includes('email')) { return true }
      if (!this.form.data.age && this.data.fields.fields.includes('age')) { return true }
      if (!this.form.data.sex && this.data.fields.fields.includes('sex')) { return true }
      if (!this.form.data.city && this.data.fields.fields.includes('city')) { return true }
      if (!this.form.data.ethnicity && this.data.fields.fields.includes('Etnia')) { return true }
      if (!this.form.data.date && this.data.fields.fields.includes('date')) { return true }
      if (!this.form.data.dropdown && this.data.fields.fields.includes('dropdown')) { return true }
      return false
    },
    years () {
      return Array.from({ length: 90 }, (value, index) => index + 10)
    }
  },

  methods: {
    next () {
      this.form.timestamps.finish = new Date()
      this.form.timestamps.valid = new Date()
      this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      this.$emit('next', this.form)
      console.log(this.form)
    }
  },

  mounted () {
    console.log('this.data', this.data)
  }
}
</script>

<style scoped lang="scss">
.grid-wrapper {
  align-items: center;
  min-height: 20vh;
  margin: auto;
}

.field-wrapper {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.5em;

  @include breakpoint($tablet) {
    width: 80%;
    transform: scale(1.5);
    margin-bottom: 1.5em;
  }
  @include breakpoint($desktop) {
    transform: scale(1);
    margin-bottom: 0.5em;
  }
}
footer {
  margin-bottom: 20px;
  @include breakpoint($tablet) {
    margin-bottom: 80px;
  }
}

.content-wrapper {
  margin: auto;
}

.html-content {
  padding-left: 5%;
  padding-right: 5%;
  h1 {
    font-size: 1.8em;
  }
}
.terms {
  font-size: 16px;
  margin-left: 0.5em;
}
</style>
<style lang="scss">
.el-select-dropdown__item.selected {
  color: $color-emphasis;
}
.el-select {
  width: 90vw;  
}

.el-input__inner {
  border-color: $color-emphasis !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: $color-emphasis !important;
  background-color: $color-emphasis !important;  
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: $color-emphasis !important;
}
.el-checkbox__inner{
  &:hover {
    border-color: $color-emphasis !important;
  }
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $gray-medium !important;
}
.el-checkbox__label {
  font-size: 16px;
  line-height: normal;
}
.el-drawer__body {
  overflow-y: scroll;
  @include breakpoint($desktop) {
    font-size: 0.8em;
  }
}
</style>
